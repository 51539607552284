/** @jsxImportSource @emotion/react */
import { VFC } from 'react';
import { mq } from '../../helpers/mediaQueryHelper';
import MemoIcnArrowLeft from '../../images/IcnArrowLeft';
import { BORDER_RADIUS } from '../../styles/borderRadius';
import { COLOR } from '../../styles/color';
import { FONT_WEIGHT } from '../../styles/fontWeight';

type Props = {
  label: string
  callback: () => void
};

export const SquareButton: VFC<Props> = (props: Props) => {
  return (
    <button css={style} onClick={ props.callback }>
      <p css={labelStyle}>{props.label}</p>
      <MemoIcnArrowLeft size={['12px', '14px', '18px', '20px', '24px']} />
    </button>
  );
};

const style = mq({
  alignItems: 'start',
  backgroundColor: 'transparent',
  borderColor: COLOR.WHITE,
  borderRadius: BORDER_RADIUS.PRIMARY,
  borderStyle: 'solid',
  borderWidth: ['1px', '2px', '2px', '3px', '4px'],
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  height: ['60px', '68px', '96px', '112px', '152px'],
  padding: ['10px', '10px', '12px', '16px', '24px'],
  width: ['56px', '68px', '96px', '112px', '152px'],
  cursor: 'pointer',
});

const labelStyle = mq({
  color: COLOR.WHITE,
  fontSize: ['9px', '9px', '12px', '16px', '24px'],
  fontWeight: FONT_WEIGHT.BOLD,
  lineHeight: '150%',
  margin: 0,
  marginBottom: ['2px', '3px', '5px', '6px', '8px'],
  // padding:['10px'],
  width: '100%',
  textAlign: 'left',
});

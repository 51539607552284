/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { mq } from '../helpers/mediaQueryHelper'
import MemoImgLogoType from '../images/ImgLogoType'
import { BORDER_RADIUS } from '../styles/borderRadius'
import { COLOR } from '../styles/color'
import { FONT_WEIGHT } from '../styles/fontWeight'
import { ToastContainer, toast } from 'react-toastify'
import { authInteractor } from '../types/api/auth/Interactor'
import { DeviceLoginParams, LoginParams } from '../../openapi-generator/dto'
import { setCredential } from '../helpers/credentials'

export const SignIn = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const tokenId = searchParams.get("tokenId");

  const deviceVerificationWithoutEmail = async (tokenId: string, macAddress: string) => {
    const params: DeviceLoginParams = {
      tokenId: tokenId,
      macAddress: macAddress
    };
    const res = await authInteractor.loginWithoutEmail(params);
  
    if (res.ok) {
      const { accessToken, refreshToken, deviceId, macAddress } = res.data
      setCredential({
        accessToken: accessToken,
        refreshToken: refreshToken,
        deviceId: String(deviceId),
        macAddress: macAddress,
      })
      navigate(`/?tokenId=${encodeURIComponent(tokenId)}`, { replace: true })

    } else {
      toast.error("認証エラー");
    }
  };

  const deviceVerification = async (email: string, macAddress: string) => {
    const params: LoginParams = {
      email: email,
      macAddress: macAddress
    };
    const res = await authInteractor.login(params);
  
    if (res.ok) {
      const { accessToken, refreshToken, deviceId, macAddress } = res.data
      setCredential({
        accessToken: accessToken,
        refreshToken: refreshToken,
        deviceId: String(deviceId),
        macAddress: macAddress
      })
      navigate(`/`, { replace: true })

    } else {
      toast.error("認証エラー");
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (tokenId){
      const target = event.target as typeof event.target & {
        macAddress: { value: string }
      }
      const macAddress = target.macAddress.value;

      deviceVerificationWithoutEmail(tokenId, macAddress);
    } else {
      const target = event.target as typeof event.target & {
        email: { value: string }
        macAddress: { value: string }
      }
      const email = target.email.value
      const macAddress = target.macAddress.value
  
      deviceVerification(email, macAddress);
    }
  }

  return (
    <div css={containerStyle(theme.colors.primary)}>
      <div css={leftColumnStyle}>
        <MemoImgLogoType width={['160px', '160px', '240px', '320px', '480px']} />
      </div>
      <div css={rightColumnStyle}>
        <ToastContainer theme='colored'></ToastContainer>
        <p css={titleStyle(theme.colors.primary)}>Log In</p>
        <form onSubmit={handleSubmit} css={formStyle}>
          {
            !tokenId && (
              <input type="email" name="email" placeholder="メールアドレス" css={inputStyle} />
            )
          }
          <input type="text" name="macAddress" placeholder="マックアドレス" css={inputStyle} />
          <input type="submit" value="ログイン" css={submitStyle(theme.colors.primary)} />
        </form>
      </div>
    </div>
  )
}

const containerStyle = (backgroundColor: string) =>
  mq({
    backgroundColor: backgroundColor,
    display: 'flex',
    flexDirection: 'row',
    padding: ['8px', '12px', '16px', '16px', '16px'],
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  })

const leftColumnStyle = mq({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingLeft: ['8px', '12px', '16px', '16px', '16px'],
  boxSizing: 'border-box',
})

const rightColumnStyle = mq({
  alignItems: 'center',
  backgroundColor: COLOR.WHITE,
  borderRadius: BORDER_RADIUS.PRIMARY,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: ['160px', '220px', '400px', '480px', '560px'],
  padding: ['8px', '8px', '24px', '28px', '32px'],
  boxSizing: 'border-box',
  flex: 1,
})

const titleStyle = (color: string) => mq({
  color: color,
  fontSize: ['14px', '18px', '32px', '40px', '48px'],
  textAlign: 'left',
  width: 'fill-available',
  fontWeight: FONT_WEIGHT.BOLD,
  margin: '0',
  marginTop: ['14px', '18px', '32px', '40px', '48px'],
})

const formStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: 'fill-available',
  marginTop: ['16px', '16px', '16px', '24px', '32px'],
})

const inputStyle = mq({
  backgroundColor: '#eeeeee',
  borderRadius: BORDER_RADIUS.SECONDARY,
  border: 'none',
  height: ['24px', '32px', '64px', '72px', '80px'],
  fontSize: ['10px', '12px', '14px', '16px', '18px'],
  padding: ['0 8px', '0 16px', '0 24px', '0 28px', '0 32px'],
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: COLOR.text.SECONDARY,
  marginTop: '16px',
})

const submitStyle = (backgroundColor: string) => mq({
  backgroundColor: backgroundColor,
  borderRadius: BORDER_RADIUS.SECONDARY,
  border: 'none',
  height: ['24px', '32px', '64px', '72px', '80px'],
  fontSize: ['10px', '12px', '14px', '16px', '18px'],
  padding: ['0 8px', '0 16px', '0 24px', '0 28px', '0 32px'],
  color: COLOR.WHITE,
  fontWeight: FONT_WEIGHT.BOLD,
  marginTop: ['16px', '16px', '16px', '24px', '32px'],
  cursor: 'pointer',
})
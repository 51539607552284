/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CSSTransition } from "react-transition-group";
import { useMenuContext } from '../contexts/menuContext'
import { openInNewTab } from '../helpers/linkHelper';
import { mq } from '../helpers/mediaQueryHelper';
import { getDashboardUri } from '../helpers/uriHelper';
import MemoImgLogoType from '../images/ImgLogoType';
import { COLOR } from '../styles/color';
import { FONT_WEIGHT } from '../styles/fontWeight';
import { MenuContextType } from '../types/MenuContextType'
import { CloseButton } from './buttons/CloseButton'
import { TextButton } from './buttons/TextButton';
import { removeCredential } from '../helpers/credentials';

export const Menu = () => {
  const menuContext: MenuContextType = useMenuContext()
  const navigate = useNavigate()
  const dashboardUri = getDashboardUri() || '/'
  const [searchParams] = useSearchParams();
  const tokenId = searchParams.get("tokenId");

  const logout = () => {
    removeCredential();
    menuContext.useSetDisplayFlag(false);
    if (tokenId){
      navigate(`/signin?tokenId=${encodeURIComponent(tokenId)}`, { replace: true })
    } else {
      navigate(`/signin`, { replace: true })
    }
  }

  return useMemo(() => {
    return (
      <CSSTransition
        in={menuContext.displayFlag}
        timeout={200}
        classNames="fade"
        unmountOnExit
      >
        <div css={containerStyle}>
          <div css={leftColumnStyle}>
            <div css={headerStyle}>
              <CloseButton
                onClick={() => {
                  menuContext.useSetDisplayFlag(false)
                }}
              />
            </div>
            <div css={leftBodyStyle}>
              <MemoImgLogoType width={['80px', '96px', '160px', '200px', '240px']} />
            </div>
          </div>
          <div css={rightColumnStyle}>
            <p css={titleStyle}>Menu</p>
            <div css={rightBodyStyle}>
              {
                !tokenId && (
                  <TextButton label='Dashboard' callback={() => { openInNewTab(dashboardUri) }} />
                )
              }
              <TextButton label='Log Out' callback={() => {
                logout()
              }}/>
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  }, [menuContext.displayFlag])
}

const containerStyle = () => mq({
  backdropFilter: 'blur(8px)',
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  height: '100%',
  left: '0',
  padding: ['8px', '12px', '16px', '16px', '16px'],
  position: 'absolute',
  top: '0',
  width: '100%',
  '&.fade-enter': {
    opacity: 0,
    backdropFilter: 'none',
  },
  '&.fade-enter-active': {
    opacity: 1,
    transition: 'opacity 200ms ease-out',
  },
  '&.fade-enter-done': {
    backdropFilter: 'blur(8px)',
    transition: 'all 100ms',
  },
  '&.fade-exit': {
    opacity: 1,
    backdropFilter: 'none',
  },
  '&.fade-exit-active': {
    opacity: 0,
    backdropFilter: 'none',
    transition: 'opacity 200ms ease-out',
  },
  '&.fade-exit-done': {
    opacity: 0,
    backdropFilter: 'none',
  },
})

const headerStyle = css({})

const leftBodyStyle = mq({
  padding: ['16px', '16px', '24px', '32px', '40px'],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  boxSizing: 'border-box',
  flex: 1,
})

const rightBodyStyle = mq({
  padding: ['16px', '16px', '24px', '32px', '40px'],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  flex: 1,
})

const leftColumnStyle = css({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const rightColumnStyle = mq({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: ['160px', '240px', '400px', '480px', '560px'],
});

const titleStyle = mq({
  fontSize: ['16px', '18px', '24px', '32px', '40px'],
  color: COLOR.WHITE,
  fontWeight: FONT_WEIGHT.BOLD,
})

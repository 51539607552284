/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from "dayjs";
import { useEffect, useState } from 'react';
import { AirQualitySign } from '../components/AirQualitySign';
import { FourDotsButton } from '../components/buttons/FourDotsButton';
import { SquareButton } from '../components/buttons/SquareButton';
import { Card } from '../components/Card';
import { DigitalClock } from '../components/DigitalClock';
import { Location } from '../components/Location';
import { Menu } from '../components/Menu';
import { useCustomThemeContext } from '../contexts/customThemeContext';
import { useMenuContext } from '../contexts/menuContext';
import { openInNewTab } from '../helpers/linkHelper';
import { mq } from '../helpers/mediaQueryHelper';
import { getThemeFromAirQualityLevel } from '../helpers/themeHelper';
import { getDashboardUri } from '../helpers/uriHelper';
import { BORDER_RADIUS } from '../styles/borderRadius';
import { COLOR } from '../styles/color';
import { Theme00 } from '../styles/theme';
import { CustomThemeContextType } from '../types/CustomThemeContextType';
import { MenuContextType } from '../types/MenuContextType';
import { isMobile } from "react-device-detect";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { deviceInteractor } from '../types/api/device/Interactor';
import { LogRequestType, LogResponseType, SortDirectionEnum } from '../types/deviceLogTypes';
import { getDeviceInfos, setCredential } from '../helpers/credentials';
import { FindDeviceResponse } from '../../openapi-generator/dto';

export const Home = () => {
  const menuContext: MenuContextType = useMenuContext()
  const customThemeContext: CustomThemeContextType = useCustomThemeContext()
  const [officeDeviceLogList, setOfficeDeviceLogList] = useState<LogResponseType | null>(null);
  const [officeDeviceInfo, setOfficeDeviceInfo] = useState<FindDeviceResponse | null>(null);
  const [airQualityLevel, ] = useState<number | null>(null)
  // const officeDeviceId = localStorage.getItem('officeDeviceId');
  const { officeDeviceId } = useParams();
  const TVOC_ALLOW_LISTS = process.env.REACT_APP_ALLOW_TVOC_LIST?.split(',');
  const {deviceId, macAddress} = getDeviceInfos();
  const [searchParams] = useSearchParams();
  const tokenId = searchParams.get("tokenId");
  const navigate = useNavigate();
  const INTERVAL = 5 * 60 * 1000;

  const fetchOfficeDeviceLogList = async (id: string,tokenId:string | null) => {
    const params: LogRequestType = {
      page:1,
      startDate: dayjs(new Date()).format("YYYY-MM-DD"),
      endDate: dayjs(new Date()).add(1, 'day').format("YYYY-MM-DD"),
      // startDate: "2023-11-01",
      // endDate: "2023-11-02",
      sortDirection: SortDirectionEnum.desc,
      // type: DeviceTypeEnum.list
    }
    const res = tokenId ? await deviceInteractor.getLogWithoutEmail(params, id, tokenId) : await deviceInteractor.getLog(params, id);
  
    if (res.ok) {
      const deviceLogList = res.data;
      setOfficeDeviceLogList(deviceLogList);
      const deviceInfos = tokenId ? await deviceInteractor.getDeviceInfosWithoutEmail(id, tokenId) : await deviceInteractor.getDeviceInfos(id);
      if (deviceInfos.ok) {
        setOfficeDeviceInfo(deviceInfos.data);
      }
    } else {
      setCredential(
        {
          accessToken:"",
          refreshToken:"",
          deviceId:"",
          macAddress: ""
        }
      )
      if (tokenId){
        navigate(`/signin?tokenId=${encodeURIComponent(tokenId)}`, { replace: true });
      } else {
        navigate(`/signin`, { replace: true });
      }
    }
  };

  useEffect(() => {
    fetchOfficeDeviceLogList(deviceId, tokenId);
    const intervalId = setInterval(() => {
      if (deviceId) {
        fetchOfficeDeviceLogList(deviceId, tokenId);
      }
    }, INTERVAL)

    if (deviceId) {
      fetchOfficeDeviceLogList(deviceId,tokenId);
    }

    return () => clearInterval(intervalId)
  }, [officeDeviceId]);

  useEffect(() => {
    const newTheme = getThemeFromAirQualityLevel(airQualityLevel || 0)
    customThemeContext.useSetTheme(newTheme)
  }, [airQualityLevel])


  return (
    <div css={containerStyle(customThemeContext.theme.colors?.primary || Theme00.colors.primary)}>
      <div css={leftColumnStyle}>
        <div css={headerStyle}>
          <FourDotsButton onClick={() => {
            menuContext.useSetDisplayFlag(true)
          }}/>
        </div>
        <div css={bodyStyle}>
          <DigitalClock />
          <div css={footerStyle}>
            <Location label={`${officeDeviceInfo?.office?.name}, ${officeDeviceInfo?.placeName}`} />
          </div>
          <div
            css={mq({
              display: 'flex',
              flexDirection: 'row',
              gap: ['4px', '4px', '8px', '12px', '16px'],
              marginTop: ['16px', '16px', '32px', '48px', '64px'],
            })}
          >
            <div
              css={mq({
                display: 'flex',
                flexDirection: 'column',
                gap: ['4px', '4px', '8px', '12px', '16px'],
              })}
            >
              {
                !tokenId && (
                  <SquareButton label="ダッシュボードへ" callback={() => { openInNewTab(getDashboardUri() || '/') }} />
                )
              }
              <Card
                id="RH"
                value={officeDeviceLogList?.logs[0]?.humidityValue || 0}
                size="SM"
                shape="SQUARE"
              />
            </div>
            <Card
              id="CO2"
              value={officeDeviceLogList?.logs[0]?.co2Value || 0}
              size="LG"
              shape="SQUARE"
            />
            <div
              css={mq({
                display: 'flex',
                flexDirection: 'column',
                gap: ['4px', '4px', '8px', '12px', '16px'],
              })}
            >
              <div css={mq({
                display: 'flex',
                flexDirection: 'row',
                gap: ['4px', '4px', '8px', '12px', '16px'],
              })}>
                <Card
                  id="TEMP"
                  value={officeDeviceLogList?.logs[0]?.temperatureValue || 0}
                  size="SM"
                  shape="SQUARE"
                />
                <Card
                  id="PM25"
                  value={officeDeviceLogList?.logs[0]?.pm25Value || 0}
                  size="SM"
                  shape="RECTANGLE"
                />
              </div>
              {
                macAddress &&
                TVOC_ALLOW_LISTS &&
                TVOC_ALLOW_LISTS.includes(macAddress) &&
                <Card
                  id="TVOC"
                  value={officeDeviceLogList?.logs[0]?.tvocValue || 0}
                  size="SM"
                  shape="RECTANGLE"
                />
              }             
            </div>
          </div>
          {
            isMobile && window.screen.width <= 667 && (
              <>
              <div css={mq({
                margin:"30px"
              })}></div>
              <div css={rightColumnStyle}>
                <AirQualitySign officeDeviceLogList={officeDeviceLogList} />
              </div>
              </>
            )
          }
        </div>
      </div>
      {
          isMobile && window.screen.width > 667 && (
            <div css={rightColumnStyle}>
              <AirQualitySign officeDeviceLogList={officeDeviceLogList} />
            </div>
          )
        }
      {
          !isMobile && window.screen.width > 667 && (
            <div css={rightColumnStyle}>
              <AirQualitySign officeDeviceLogList={officeDeviceLogList} />
            </div>
          )
        }
      <Menu />
    </div>
  )
}

const containerStyle = (backgroundColor: string) =>
  mq({
    backgroundColor: backgroundColor,
    display: ["block","block","block","flex","flex"],
    flexDirection: 'row',
    height: ['100%','100%','100%','100%','100%'],
    width: '100%',
    justifyContent: 'space-between',
    padding: ['8px', '12px', '16px', '16px', '16px'],
    boxSizing: 'border-box'
  });

const leftColumnStyle = css({
  display: ["block","block","flex","flex","flex"],
  flexDirection: 'column',
});

const rightColumnStyle = mq({
  alignItems: 'center',
  backgroundColor: COLOR.WHITE,
  borderRadius: BORDER_RADIUS.PRIMARY,
  display: ["block","block","flex","flex","flex"],
  flexDirection: 'column',
  maxWidth: ['100%', '100%', '100%', '480px', '560px'],
  padding: ['8px', '8px', '24px', '28px', '32px'],
  flex: 1,
  boxSizing: 'border-box',
});

const headerStyle = css({});

const bodyStyle = mq({
  padding: ['24px 8px', '32px 16px', '64px 24px', '64px 32px', '64px 40px'],
  flex: 1,
});

const footerStyle = css({});

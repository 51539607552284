import { ReactNode } from 'react';
import { Navigate, useLocation,useSearchParams } from 'react-router-dom';
import { getAuthCookies } from '../helpers/credentials';

type Props = {
  component: ReactNode;
  redirect: string;
};

export const RouteAuthGuard = (props: Props) => {
  // const authCompany = useAuthCompanyContext().officeDevice;
  const {accessToken} = getAuthCookies();
  // const [searchParams] = useSearchParams();
  

  if (!accessToken) {
    return (
      <Navigate
        to={props.redirect}
        state={{ from: useLocation() }}
        replace={false}
      />
    );
  }

  // if(searchParams.get('device') !== null){
  //   localStorage.setItem('officeDeviceId', String(searchParams.get('device')));
  // }

  return <>{props.component}</>;
};

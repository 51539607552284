import { SSMClient, GetParameterCommand } from "@aws-sdk/client-ssm"

const client = new SSMClient({
    region: "ap-northeast-1", 
    credentials: {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID || "", 
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY || "", 
    },
  });

export const getParameter = async (parameterName:string):Promise<string | undefined> => {
    try {
      const command = new GetParameterCommand({
        Name: parameterName, 
        WithDecryption: true, 
      });
  
      const response = await client.send(command);
      return response?.Parameter?.Value;
    } catch (error) {
      console.error("Error fetching parameter:", error);
      return undefined;
    }
  };
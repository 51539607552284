import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from '../pages/Home';
import { Layout } from '../pages/Layout';
import { NotFound } from '../pages/NotFound';
import { SignIn } from '../pages/SignIn';
import { RouteAuthGuard } from './RouteAuthGuard';

export const RouterConfig: React.VFC = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={
                <RouteAuthGuard component={<Home />} redirect="/signin" />
              }
            />
            <Route path=":officeDeviceId" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

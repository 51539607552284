

import { DeviceLoginParams, LoginParams, LoginResponse } from "../../../../openapi-generator/dto"
import { get, post } from "../../../helpers/fetch";

export const path = `${process.env.REACT_APP_API_URI}/ex-app/auth`

export const authInteractor = {
  openIdConnect: async (params: object) => {
    return await get<LoginResponse>(`${path}/openid-connect`, params)
  },
  login: async (params: LoginParams) => {
    return await post<LoginResponse>(`${path}/verification`, params)
  },
  loginWithoutEmail: async (params: DeviceLoginParams) => {
    return await post<LoginResponse>(`${path}/verification/token-id`, params)
  }
}

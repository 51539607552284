import Cookies from "js-cookie"
import { Credential } from "../types/GlobalState"

const CredentialKeys = {
  accessToken: "accessToken",
  refreshToken: "refreshToken",
  deviceId: "deviceId",
  macAddress: "macAddress"
}

/**
 * 保存
 */

// sx-authに合わせてoptionを設定
const cookieOptions: Cookies.CookieAttributes = {
  expires: 30,
  sameSite: "None",
  secure: true,
}

export const setCredential = (credentials: Credential) => {
  Cookies.set(CredentialKeys.accessToken, credentials.accessToken || "", cookieOptions)
  Cookies.set(CredentialKeys.refreshToken, credentials.refreshToken || "", cookieOptions)
  Cookies.set(CredentialKeys.deviceId, credentials.deviceId || "", cookieOptions)
  Cookies.set(CredentialKeys.macAddress, credentials.macAddress || "", cookieOptions)
}

export const removeCredential = () => {
  Cookies.remove(CredentialKeys.accessToken);
  Cookies.remove(CredentialKeys.refreshToken);
  Cookies.remove(CredentialKeys.deviceId);
  Cookies.remove(CredentialKeys.macAddress);
}

/**
 * ストレージ情報からオブジェクトを復元
 * @returns {Credential} クレデンシャルオブジェクト
 */
export const getAuthCookies = () => {
  const accessToken = Cookies.get(CredentialKeys.accessToken) || ""
  const refreshToken = Cookies.get(CredentialKeys.refreshToken) || ""
  return { accessToken, refreshToken }
}

export const getDeviceInfos = () => {
  const deviceId = Cookies.get(CredentialKeys.deviceId) || ""
  const macAddress = Cookies.get(CredentialKeys.macAddress) || ""
  return { deviceId, macAddress }
}

/** @jsxImportSource @emotion/react */
import { Transition } from 'react-transition-group'
import { useEffect, useState } from 'react'
import { css, useTheme } from '@emotion/react'
import { COLOR } from '../styles/color'
import { FONT_WEIGHT } from '../styles/fontWeight'
import { BORDER_RADIUS } from '../styles/borderRadius'
import MemoIcnHintCircle from '../images/IcnHintCircle'
import { mq } from '../helpers/mediaQueryHelper'
import MemoIcnCloseCircle from '../images/IcnCloseCircle'
import { AIR_QUALITY_VARIATION, AIR_QUALITY_VARIATION_TYPE } from '../helpers/airQualityHelper'

type Props = {
  id: keyof AIR_QUALITY_VARIATION_TYPE
  value: number
  size: SizeType
  shape: ShapeType
}

export const Card = (props: Props) => {
  const theme = useTheme()
  const [flip, setFlip] = useState(false)
  const [overThreshold, setOverThreshold] = useState(false)
  const labelEn = AIR_QUALITY_VARIATION[props.id].LABEL.EN
  const labelJa = AIR_QUALITY_VARIATION[props.id].LABEL.JA
  const unit = AIR_QUALITY_VARIATION[props.id].UNIT
  const upperThreshold = AIR_QUALITY_VARIATION[props.id].THRESHOLD.UPPER.FIRST
  const lowerThreshold = AIR_QUALITY_VARIATION[props.id].THRESHOLD.LOWER.FIRST

  useEffect(() => {
    const isOverThreshold = lowerThreshold ?
      (upperThreshold <= props.value || props.value <= lowerThreshold) : upperThreshold <= props.value

    setOverThreshold(isOverThreshold)
  }, [props.value])

  return (
    <Transition in={flip} timeout={550}>
      {(state: FlipStyleType) => (
        <div
          css={containerStyle(props.size, props.shape)}
          style={FLIP_STYLE[state]}
        >
          <button
            css={[cardStyle, cardFrontStyle]}
            onClick={() => setFlip(true)}
          >
            <div css={headerStyle}>
              <div css={headerLeftStyle}>
                <span
                  css={[
                    labelStyle(),
                    labelEnStyle(props.size, props.shape, flip),
                  ]}
                >
                  {labelEn}
                </span>
                <span
                  css={[
                    labelStyle(),
                    labelJaStyle(props.size, props.shape, flip),
                  ]}
                >
                  {labelJa}
                </span>
              </div>
              <div css={headerRightStyle}>
                <MemoIcnHintCircle size={['14px', '14px', '18px', '18px', '24px']} />
              </div>
            </div>
            <div css={bodyStyle}>
              <span css={valueStyle(props.size, props.shape, flip, overThreshold)}>
                {props.value.toLocaleString()}
              </span>
              <span css={unitStyle(props.size, props.shape, flip, overThreshold)}>
                {unit}
              </span>
            </div>
          </button>
          <button
            css={[cardStyle, cardBackStyle(theme.colors.primary)]}
            onClick={() => setFlip(false)}
          >
            <div css={headerStyle}>
              <div css={headerLeftStyle}>
                <span
                  css={[
                    labelStyle(),
                    labelEnStyle(props.size, props.shape, flip),
                  ]}
                >
                  {labelEn}
                </span>
                <span
                  css={[
                    labelStyle(),
                    labelJaStyle(props.size, props.shape, flip),
                  ]}
                >
                  基準値
                </span>
              </div>
              <div css={headerRightStyle}>
                <MemoIcnCloseCircle size={['14px', '14px', '18px', '18px', '24px']} />
              </div>
            </div>
            <div css={bodyStyle}>
              <span css={valueStyle(props.size, props.shape, flip, overThreshold)}>
                {upperThreshold.toLocaleString()}
              </span>
              <span css={unitStyle(props.size, props.shape, flip, overThreshold)}>
                {unit}
              </span>
              {(
                lowerThreshold ? 
                  <span css={lowerThresholdStyle(props.size, props.shape, flip, overThreshold)}>
                    {lowerThreshold.toLocaleString()}
                    {unit}
                  </span>
                  :
                  <span></span>
              )}
            </div>
          </button>
        </div>
      )}
    </Transition>
  )
}

type SizeInterface = {
  [key: string]: {
    [key: string]: {
      [key: string]: Array<string>
    }
  }
}

const variants: SizeInterface = {
  SM: {
    SQUARE: {
      WIDTH: ['56px', '68px', '96px', '112px', '152px'],
      HEIGHT: ['56px', '68px', '96px', '112px', '152px'],
      LABEL_EN_FS: ['8px', '12px', '16px', '18px', '24px'],
      LABEL_JA_FS: ['6px', '7px', '9px', '10px', '12px'],
      VALUE_FS: ['10px', '12px', '17px', '22px', '32px'],
      UNIT_FS: ['8px', '8px', '10.5px', '13px', '18px'],
      LOWER_THRESHOLD_FS: ['8px', '8px', '10.5px', '13px', '18px'],
    },
    RECTANGLE: {
      WIDTH: ['96px', '140px', '200px', '240px', '320px'],
      HEIGHT: ['56px', '68px', '96px', '112px', '152px'],
      LABEL_EN_FS: ['8px', '12px', '18px', '18px', '24px'],
      LABEL_JA_FS: ['6px', '7px', '10px', '10px', '12px'],
      VALUE_FS: ['10px', '12px', '22px', '22px', '32px'],
      UNIT_FS: ['8px', '8px','13px', '13px', '18px'],
      LOWER_THRESHOLD_FS: ['8px', '8px','13px', '13px', '18px'],
    },
  },
  LG: {
    SQUARE: {
      WIDTH: ['120px', '140px', '200px', '240px', '320px'],
      HEIGHT: ['120px', '140px', '200px', '240px', '320px'],
      LABEL_EN_FS: ['18px', '28px', '36px', '40px', '48px'],
      LABEL_JA_FS: ['8px', '14px', '18px', '20px', '24px'],
      VALUE_FS: ['18px', '32px', '48px', '56px', '72px'],
      UNIT_FS: ['10px', '16px', '24px', '28px', '36px'],
      LOWER_THRESHOLD_FS: ['10px', '16px', '24px', '28px', '36px'],
    },
  },
}

const containerStyle = (size: SizeType, shape: ShapeType) =>
  mq({
    border: 'none',
    height: variants[size][shape].HEIGHT,
    position: 'relative',
    transformStyle: 'preserve-3d',
    width: variants[size][shape].WIDTH,
  })

const cardStyle = mq({
  backfaceVisibility: 'hidden',
  borderColor: COLOR.WHITE,
  borderRadius: BORDER_RADIUS.PRIMARY,
  borderStyle: 'solid',
  borderWidth: ['1px', '2px', '2px', '3px', '4px'],
  bottom: '0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  left: '0',
  padding: ['8px', '6px', '8px', '16px', '20px'],
  position: 'absolute',
  right: '0',
  top: '0',
})

const cardFrontStyle = css({
  backgroundColor: COLOR.WHITE,
})

const cardBackStyle = (backgroundColor: string) =>
  css({
    backgroundColor: backgroundColor,
    transform: 'rotateY(180deg)',
  })

const headerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
})

const headerLeftStyle = mq({
  alignItems: 'start',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: ['2px', '2px', '4px', '4px', '8px'],
})

const headerRightStyle = css({})

const labelStyle = () =>
  css({
    lineHeight: '100%',
  })

const labelEnStyle = (size: SizeType, shape: ShapeType, flip: boolean) =>
  mq({
    color: flip ? COLOR.WHITE : COLOR.text.PRIMARY,
    fontSize: variants[size][shape].LABEL_EN_FS,
    fontWeight: FONT_WEIGHT.MEDIUM,
  })

const labelJaStyle = (size: SizeType, shape: ShapeType, flip: boolean) =>
  mq({
    color: flip ? COLOR.WHITE : COLOR.text.SECONDARY,
    fontSize: variants[size][shape].LABEL_JA_FS,
    fontWeight: FONT_WEIGHT.REGULAR,
  })

const bodyStyle = css({
  alignItems: 'flex-end',
  display: 'flex',
  flexDirection: 'row',
  gap: '2px',
  justifyContent: 'start',
  width: '100%',
})

const valueStyle = (size: SizeType, shape: ShapeType, flip: boolean, overThreshold: boolean) =>
  mq({
    color: flip ? COLOR.WHITE : (overThreshold ? COLOR.RED : COLOR.text.PRIMARY),
    fontSize: variants[size][shape].VALUE_FS,
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: '100%',
  })

const unitStyle = (size: SizeType, shape: ShapeType, flip: boolean, overThreshold: boolean) =>
  mq({
    color: flip ? COLOR.WHITE : (overThreshold ? COLOR.RED : COLOR.text.PRIMARY),
    fontSize: variants[size][shape].UNIT_FS,
    fontWeight: FONT_WEIGHT.MEDIUM,
  })

const lowerThresholdStyle = (size: SizeType, shape: ShapeType, flip: boolean, overThreshold: boolean) =>
  mq({
    color: flip ? COLOR.WHITE : (overThreshold ? COLOR.RED : COLOR.text.PRIMARY),
    fontSize: variants[size][shape].LOWER_THRESHOLD_FS,
    fontWeight: FONT_WEIGHT.MEDIUM,
    marginLeft: 'auto',
  })

const ALL_SIZE = {
  SM: 'SM',
  LG: 'LG',
} as const
type SizeType = keyof typeof ALL_SIZE

const ALL_SHAPE = {
  SQUARE: 'SQUARE',
  RECTANGLE: 'RECTANGLE',
} as const
type ShapeType = keyof typeof ALL_SHAPE

const FLIP_STYLE = {
  entering: {
    transition: 'all .5s ease',
    transform: 'perspective(600px) rotateY(-180deg)',
  },
  entered: {
    transition: '',
    transform: 'perspective(600px) rotateY(-180deg)',
  },
  exiting: {
    transition: 'all .5s ease',
    transform: 'perspective(600px) rotateY(-360deg)',
  },
  exited: {
    transition: '',
    transform: 'perspective(600px) rotateY(0)',
  },
  unmounted: {
    
  }
}
type FlipStyleType = keyof typeof FLIP_STYLE

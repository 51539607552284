export enum DeviceTypeEnum {
    "list" = "list",
    "chart" = "chart"
}

export enum SortDirectionEnum {
    "asc" = "asc",
    "desc" = "desc"
}

export type LogRequestType = {
    page: number,
    startDate: string,
    endDate: string,
    type?: DeviceTypeEnum,
    sortDirection: SortDirectionEnum,
}

export type LogResponseType = {
    logs: DeviceLogContent[]
    totalCount: number
    totalPage: number
}

export type DeviceLogContent = {
    deviceId: string
    date: string
    batteryStatus: number
    batteryValue: number
    co2Status: number
    co2Value: number
    humidityStatus: number
    humidityValue: number
    pm10Status: number
    pm10Value: number
    pm25Status: number
    pm25Value: number
    temperatureStatus: number
    temperatureValue: number
    tvocStatus: number
    tvocStatusDuration: number
    tvocStatusStartTime: number
    tvocValue: number
    timestampValue: number
    macAddress: string
    placeName: string
    officeName: string
  }
  
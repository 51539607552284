import { ReactNode } from 'react'
import { CustomThemeProvider } from '../providers/CustomThemeProvider'
import { MenuProvider } from '../providers/MenuProvider'

type Props = {
  children: ReactNode
}

export const Providers = (props:any) => {
  return (
    <>
      <CustomThemeProvider>
          <MenuProvider>{props.children}</MenuProvider>
      </CustomThemeProvider>
    </>
  )
}

/** @jsxImportSource @emotion/react */
import { getAirQualityImage, getAirQualityLevel, getAirQualityMessage } from '../helpers/airQualityHelper'
import { mq } from '../helpers/mediaQueryHelper'
import { COLOR } from '../styles/color'
import { FONT_WEIGHT } from '../styles/fontWeight'
import { AirQualitySignGuide } from './AirQualitySignGuide'
import { LogResponseType } from '../types/deviceLogTypes'

type Props = {
  officeDeviceLogList: LogResponseType | null,

}

export const AirQualitySign = (props: Props) => {
  const airQualityLevel = getAirQualityLevel(
    props.officeDeviceLogList?.logs[0]?.co2Value || 0,
    props.officeDeviceLogList?.logs[0]?.tvocValue || 0,
    props.officeDeviceLogList?.logs[0]?.pm25Value || 0,
  );

  return (
    <div css={style}>
      <p
        css={messageStyle}
      >{ getAirQualityMessage(airQualityLevel) }</p>
      { getAirQualityImage(airQualityLevel) }
      <AirQualitySignGuide />
    </div>
  )
}

const style = mq({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  paddingTop: ['16px', '32px', '56px', '88px', '120px']
})

const messageStyle = mq({
  color: COLOR.text.PRIMARY,
  fontSize: ['10px', '12px', '24px', '28px', '32px'],
  fontWeight: FONT_WEIGHT.MEDIUM,
  lineHeight: '150%',
  margin: 0,
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
})

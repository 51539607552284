import { FindDeviceResponse } from '../../../../openapi-generator/dto';
import { get } from '../../../helpers/fetch';
import { LogRequestType, LogResponseType } from '../../deviceLogTypes';


export const path = `${process.env.REACT_APP_API_URI}/ex-app/devices/`

export const deviceInteractor = {
  getLogWithoutEmail: async (params: LogRequestType, id: string, tokenId: string) => {
    return await get<LogResponseType>(`${path}without-email/${id}/logs`, {
      ...params,
      tokenId
    })
  },

  getDeviceInfosWithoutEmail: async (id: string, tokenId: string) => {
    return await get<FindDeviceResponse>(`${path}without-email/${id}`,{ tokenId })
  },

  getLog: async (params: LogRequestType, id: string) => {
    return await get<LogResponseType>(`${path}${id}/logs`, params)
  },

  getDeviceInfos: async (id: string) => {
    return await get<FindDeviceResponse>(`${path}${id}`,{})
  },
}
